<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>
    <div>
      <v-text-field
        v-model="scode"
        label="所属コード"
        prepend-icon="mdi-office-building"
        type="number"
        outlined
        :disabled="!edit"
        :rules="[Rules.Required]"
      ></v-text-field>
      <v-text-field
        v-model="sname"
        label="所属名"
        prepend-icon="mdi-office-building"
        outlined
        :disabled="!edit"
        :rules="[Rules.Required]"
      ></v-text-field>
      <v-radio-group
        v-model="isValid"
        row
        label="レコード状態"
        :rules="Required"
        :disabled="!edit"
      >
        <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
      </v-radio-group>
    </div>
    <v-form v-model="valid" ref="shozokumaster" :disabled="!edit">
      <div>
        <v-tabs v-model="tabs" color="blue">
          <v-tab v-for="item in tabItems" :key="item" :disabled="!edit">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" class="home pa-3">
          <v-tab-item>
            <Btn
              color="success"
              icon="plus"
              text
              @click="addPattern()"
              :disabled="!edit"
              >パターン追加</Btn
            >
            <table v-if="!this.shozokuExpTankaList.length == 0">
              <thead>
                <tr>
                  <th v-for="(header, i) in shozokuExpTankaDataHeader" :key="i">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in this.shozokuExpTankaList"
                  :key="item.serialNumber"
                >
                  <td>
                    <label>{{ item.serialNumber }}</label>
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="item.kbn"
                      :items="divisionList"
                      outlined
                      item-text="name"
                      item-value="code"
                      :disabled="!edit"
                      :rules="[Rules.Required]"
                    ></v-autocomplete>
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="item.tanka"
                      type="number"
                      step="1"
                      min="0"
                      :disabled="!edit"
                      :rules="[Rules.Required]"
                    ></v-text-field>
                  </td>
                  <td>
                    <div>
                      <vue-timepicker
                        v-model="item.dispStartTime"
                        close-on-complete
                        drop-direction="up"
                        :format="timeFormat"
                        placeholder="時間"
                        :minute-interval="minInterval"
                        hour-label="時"
                        minute-label="分"
                        :rules="[Rules.Required]"
                        :disabled="Number(item.kbn) == 1"
                      ></vue-timepicker>
                    </div>
                  </td>
                  <td>
                    <vue-timepicker
                      v-model="item.dispEndTime"
                      close-on-complete
                      drop-direction="up"
                      :format="timeFormat"
                      placeholder="時間"
                      :minute-interval="minInterval"
                      hour-label="時"
                      minute-label="分"
                      :rules="[Rules.Required]"
                      :disabled="Number(item.kbn) == 1"
                    ></vue-timepicker>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="item.monday"
                      :disabled="Number(item.kbn) == 1"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="item.tuesday"
                      :disabled="Number(item.kbn) == 1"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="item.wednesday"
                      :disabled="Number(item.kbn) == 1"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="item.thursday"
                      :disabled="Number(item.kbn) == 1"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="item.friday"
                      :disabled="Number(item.kbn) == 1"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="item.saturday"
                      :disabled="Number(item.kbn) == 1"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="item.sunday"
                      :disabled="Number(item.kbn) == 1"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="item.nationalHoliday"
                      :disabled="Number(item.kbn) == 1"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-icon
                      large
                      @click="removePattern(item)"
                      class="ml-3"
                      color="error"
                      :disabled="!edit"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-tab-item>
        </v-tabs-items>
        <div class="d-flex flex-row-reverse">
          <v-btn color="success" :disabled="!edit" @click="submit()">
            完了
          </v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";

const SelectsDelete = {
  Delete: "0",
  Default: "1"
};

export default {
  name: "ShozokuMasterDetails",
  components: { VueTimepicker },
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      tabs: 0, // Opens Tab 1 by default. 0-indexed.
      tabItems: ["単価情報"],
      shozokuExpTankaDataHeader: [
        "パターン",
        "区分",
        "割増単価",
        "開始時間",
        "終了時間",
        "月",
        "火",
        "水",
        "木",
        "金",
        "土",
        "日",
        "祝",
        "actions"
      ],

      SelectsDelete,
      shozokumaster: {
        incode: "",
        sname: "",
        scode: "",
        isValid: SelectsDelete.Default
      },
      divisionList: [],
      timeFormat: "HH:mm",
      minInterval: 15,
      shozokuExpTankaList: [],
      isNumber: value => Number.isInteger(value) || "整数で入力してください。",
      isNull: value => value != null || "必須入力です。",
      Required: [value => value != null || "必須入力です"],
      inputText: "",
      isValid: SelectsDelete.Default
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    },
    canadd() {
      if (this.add === true) {
        return false;
      } else {
        return true;
      }
    }
  },
  // watch: {
  //   shozokumaster(inputText) {
  //     this.shozokumaster.scode = this.charaLimit(inputText);
  //   }
  // },
  methods: {
    async submit() {
      if (!this.$refs.shozokumaster.validate()) return;
      console.log("submit", this.shozokuExpTankaList);
      console.log("len", this.scode);
      if (this.scode == undefined || this.scode.length == "") {
        this.$error("所属コードを入力してください。");
        return;
      } else if (this.scode.length > 5) {
        this.$error("所属コードは、5桁以内で入力してください。");
        return;
      } else {
        const num = this.scode;
        const str = ("00000" + num).slice(-5);
        this.scode = str;
      }
      if (this.sname == undefined || this.sname == "") {
        this.$error("所属名を入力してください。");
        return;
      }
      this.$loading();
      try {
        console.log("add", this.add);
        const shozokuTankaList = this.getFormatedShozokuExpansionTanka(
          this.shozokuExpTankaList
        );
        let response;
        if (this.add) {
          const shozoku = {
            incode: this.shozokumaster.incode,
            sname: this.sname,
            scode: this.scode,
            isValid: this.isValid
          };
          response = await this.$post(this.Paths.shozokuMaster, shozoku);
          if (response.status === "FAIL") {
            this.$error(response.message);
          } else {
            //this.shozokuExpTankaList = this.getFormatedShozokuExpansionTanka(
            //this.shozokuExpTankaList
            // );
            this.shozokumaster.incode = response.incode;
            shozokuTankaList.map(obj => {
              obj.incode = response.incode;
            });
            console.log("upodattanka", shozokuTankaList);
            await this.$put(this.Paths.shozokuExpTanka, shozokuTankaList);
            this.add = false;
            this.edit = false;
            this.$info("更新しました。", "所属マスタ");
          }
        } else {
          console.log("edit");
          //this.getFormatedShozokuExpansionTanka(this.shozokuExpTankaList);

          const shozoku = {
            incode: this.shozokumaster.incode,
            sname: this.sname,
            scode: this.scode,
            isValid: this.isValid
          };
          console.log("shozokumster", this.shozokumaster);
          response = await this.$put(this.Paths.shozokuMaster, shozoku);
          console.log("edit", response);
          if (response.status === "FAIL") {
            this.$error(response.message);
          } else {
            //this.shozokuExpTankaList = this.getFormatedShozokuExpansionTanka(
            //  this.shozokuExpTankaList
            //);
            //this.getFormatedShozokuExpansionTanka(this.shozokuExpTankaList);
            response = await this.$put(
              this.Paths.shozokuExpTanka,
              shozokuTankaList
            );
            this.add = false;
            this.$info("更新しました。", "所属マスタ");
          }
        }
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/shozokumaster";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    getSerialNimber(empTankaList) {
      const newList = empTankaList.map(item => {
        return item.serialNumber;
      });
      const listLength = newList.length;
      if (listLength == 0) return 1;
      else {
        for (let i = 1; i <= listLength; i++) {
          if (!newList.includes(i)) {
            return i;
          } else if (i == newList.length) {
            return i + 1;
          } else {
            continue;
          }
        }
      }
    },
    addPattern() {
      const json = {
        incode: this.shozokumaster.incode,
        serialNumber: this.getSerialNimber(this.shozokuExpTankaList),
        //kbn: "1",
        //tanka: "1",
        dispStartTime: "00:00",
        dispEndTime: "00:00",
        monday: 1,
        tuesday: 1,
        wednesday: 1,
        thursday: 1,
        friday: 1,
        saturday: 1,
        sunday: 1,
        nationalHoliday: 1
      };
      this.shozokuExpTankaList.push(json);
      this.shozokuExpTankaList.sort(
        (a, b) => Number(a.serialNumber) - Number(b.serialNumber)
      );
    },
    removePattern(item) {
      this.shozokuExpTankaList = this.shozokuExpTankaList.filter(
        t => t !== item
      );
    },
    getFormatedShozokuExpansionTanka(param) {
      let result = [];
      result = param.map(
        ({
          incode,
          serialNumber,
          kbn,
          tanka,
          dispStartTime,
          dispEndTime,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
          nationalHoliday
        }) => {
          return {
            incode,
            serialNumber,
            kbn,
            tanka,
            dispStartTime,
            dispEndTime,
            monday: Number(monday),
            tuesday: Number(tuesday),
            wednesday: Number(wednesday),
            thursday: Number(thursday),
            friday: Number(friday),
            saturday: Number(saturday),
            sunday: Number(sunday),
            nationalHoliday: Number(nationalHoliday)
          };
        }
      );
      return result;
    },
    async getShozokuExpansionTanka() {
      const query = { shozokuIncode: this.shozokumaster.incode };
      const encode = encodeURI(JSON.stringify(query));
      const data = await this.$get(
        this.Paths.shozokuExpTanka,
        "query=" + encode
      );
      //this.shozokuExpTankaList = data; //
      this.shozokuExpTankaList = this.getFormatedShozokuExpansionTanka(data);
      console.log("erer", data);
    },
    //#region GetAPI
    async getDivisionList() {
      console.log("getDivisionList");
      const params = { kbn: "STNK" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    }
    // charaLimit(inputText) {
    //   return inputText.length > 4 ? inputText.slice(0, -1) : inputText;
    // }
    //#endregion
  },
  async created() {
    console.log(this.name, "created");
    const params = this.$route.params;
    const prosess = params.prosess;
    this.params = params;
    console.log("filter", this.params.filter);

    this.$loading();
    try {
      if (prosess && typeof prosess === "string")
        this.params.prosess = prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }
      const promiseList = [this.getDivisionList()];
      const [division] = await Promise.all(promiseList);

      this.divisionList = division;
      this.isValid = this.SelectsDelete.Default;
      if (!this.add) {
        const args = this.args;
        this.scode = args.scode;
        this.sname = args.sname;
        if (args.isValid == "1") {
          this.isValid = this.SelectsDelete.Default;
        } else {
          this.isValid = this.SelectsDelete.Delete;
        }
        this.shozokumaster = args;
        this.getShozokuExpansionTanka();
        console.log("args", args);
        console.log("promise");
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style>
.base {
  margin-bottom: 30px;
}
.base1 basetab {
  width: 30px;
}
table {
  border-spacing: 10px 2px;
}
</style>
